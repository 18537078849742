import React from 'react';

import webshopOntwerpImage1 from 'assets/images/pages/diensten_webshopontwerp/1.jpg';
import webshopOntwerpImage2 from 'assets/images/pages/diensten_webshopontwerp/2.jpg';
import webshopOntwerpImage3 from 'assets/images/pages/diensten_webshopontwerp/3.jpg';
import grafischImage from 'assets/images/pages/diensten_grafischdesign/1.jpg';
import frontendImage from 'assets/images/pages/diensten_frontenddesign/1.jpg';

import Title from 'blocks/Title';
import Grid from 'blocks/Grid';
import Image from 'blocks/Image';
import Content from 'blocks/Content';
import ContactCta from 'blocks/ContactCta';

import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Section from 'components/Section';
import Heading from 'components/Heading';
import ImageBlock from 'components/ImageBlock';
import Text from 'components/Text';
import Button from 'components/Button';
import Quote from 'components/Quote';

const DienstenOntwerp = () => (
  <Layout>
    <Seo image={{ src: webshopOntwerpImage1 }} />

    <Title
      image={{ src: webshopOntwerpImage1 }}
      alt="Webshop laten ontwerpen"
      subtitle="Ons ontwerp, jullie uitstraling"
      title="Webshop laten ontwerpen"
    >
      Het is het eerste waar een klant bij binnenkomst naar kijkt; het ontwerp
      van je webshop. Deze split-second beslissing kan het verschil betekenen
      tussen conversie en klanten die de webshop direct weer wegklikken.
    </Title>

    <Section>
      <Image
        image={{ src: webshopOntwerpImage2 }}
        alt="Jouw webshop, onze zorg"
      >
        <Heading size="xl" as="h2">
          Jouw webshop, onze zorg
        </Heading>

        <Text spacing="sm">
          Het is het eerste waar een (potentiële) klant bij binnenkomst naar
          kijkt; het ontwerp van je webshop. Wij zijn ons ervan bewust dat deze
          split-second het verschil kan betekenen tussen conversie en klanten
          die de webshop direct weer wegklikken. Onze ontwerpers zijn ware
          kunstenaars die het belang van een goed design snappen, om zo tot
          doelgerichte ontwerpen te komen. Simpel, modern en daadkrachtig waar
          nodig, uitsprekend en opvallend waar mogelijk.
        </Text>

        <Text>
          Een webshop laten ontwerpen door Batao betekent een intensieve
          samenwerking, waarbij onze ontwerpers het belangrijk vinden om jou en
          jouw bedrijf te leren kennen.
        </Text>
      </Image>
    </Section>

    <Section>
      <Content wide>
        <Quote author="Mark Bodde" position="Ontwerper Batao">
          “Wij vinden het belangrijk dat het ontwerp van een webshop niet alleen
          doel- en conversiegericht is, maar ook dat het design uitstraalt wat
          het moet uitstralen. Niet alleen in tekst maar ook in beeld moeten
          klanten direct kunnen zien waar een organisatie voor staat; wat de
          bedrijfscultuur en bedrijfswaarden zijn. Pas wanneer je een
          organisatie echt goed leert kennen, ben je als ontwerper in staat de
          juiste snaar te raken.”
        </Quote>
      </Content>
    </Section>

    <Section>
      <Image
        image={{ src: webshopOntwerpImage3 }}
        alt="De gebruiker voorop"
        position="right"
      >
        <Heading size="xl" as="h2">
          De gebruiker voorop
        </Heading>

        <Text>
          In onze ontwerpen houden we altijd rekening met de user experience.
          Een webshop kan er namelijk nog zo mooi uitzien en technisch bijzonder
          goed in elkaar zitten, wanneer een gebruiker het idee van de webshop
          niet snapt en niet kan vinden wat hij of zij zoekt, heeft het allemaal
          geen meerwaarde. Onze (UX-)designers zijn niet alleen gespecialiseerd
          in het neerzetten van de meest prachtige ontwerpen, ze weten ook
          precies wat wel en niet werkt! Bekijk hieronder welke diensten wij jou
          aan kunnen bieden. Wil je een webshop laten ontwerpen en ben je
          benieuwd wat de mogelijkheden zijn? Neem dan contact op met Janko,
          onze projectmanager.
        </Text>
      </Image>
    </Section>

    <Section>
      <Grid>
        <ImageBlock
          title="Grafisch design"
          image={{ src: grafischImage }}
          to="/diensten/webshop-ontwerp/grafish-design"
        >
          <Text color="white" spacing="lg">
            Wij zijn jouw full service e-commerce partner en kijken verder dan
            alleen het front-end design van een webshop.
          </Text>

          <Button as="span" color="white" hoverColor="secondary" outline>
            Lees verder
          </Button>
        </ImageBlock>

        <ImageBlock
          title="Front-end design"
          image={{ src: frontendImage }}
          to="/diensten/webshop-ontwerp/frontend-design"
        >
          <Text color="white" spacing="lg">
            Als full-service E-commerce bureau helpen wij jou bij de
            ontwikkeling van je volledige E-commerce platform.
          </Text>

          <Button as="span" color="white" hoverColor="secondary" outline>
            Lees verder
          </Button>
        </ImageBlock>

        <Quote author="Remco de Jong" position="Co Founder Batao" small>
          “Met Batao weet je zeker dat je toegang hebt tot een team van top
          IT-ers met een passie voor techniek, design en eCommerce.”
        </Quote>
      </Grid>
    </Section>

    <ContactCta />
  </Layout>
);

export default DienstenOntwerp;
